// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-materials-js": () => import("./../../../src/pages/materials.js" /* webpackChunkName: "component---src-pages-materials-js" */),
  "component---src-pages-nl-404-js": () => import("./../../../src/pages/nl/404.js" /* webpackChunkName: "component---src-pages-nl-404-js" */),
  "component---src-pages-nl-algemene-voorwaarden-js": () => import("./../../../src/pages/nl/algemene-voorwaarden.js" /* webpackChunkName: "component---src-pages-nl-algemene-voorwaarden-js" */),
  "component---src-pages-nl-index-js": () => import("./../../../src/pages/nl/index.js" /* webpackChunkName: "component---src-pages-nl-index-js" */),
  "component---src-pages-nl-materialen-js": () => import("./../../../src/pages/nl/materialen.js" /* webpackChunkName: "component---src-pages-nl-materialen-js" */),
  "component---src-pages-nl-ons-verhaal-js": () => import("./../../../src/pages/nl/ons-verhaal.js" /* webpackChunkName: "component---src-pages-nl-ons-verhaal-js" */),
  "component---src-pages-nl-pers-js": () => import("./../../../src/pages/nl/pers.js" /* webpackChunkName: "component---src-pages-nl-pers-js" */),
  "component---src-pages-nl-privacy-js": () => import("./../../../src/pages/nl/privacy.js" /* webpackChunkName: "component---src-pages-nl-privacy-js" */),
  "component---src-pages-nl-producten-js": () => import("./../../../src/pages/nl/producten.js" /* webpackChunkName: "component---src-pages-nl-producten-js" */),
  "component---src-pages-nl-retour-js": () => import("./../../../src/pages/nl/retour.js" /* webpackChunkName: "component---src-pages-nl-retour-js" */),
  "component---src-pages-nl-verzending-js": () => import("./../../../src/pages/nl/verzending.js" /* webpackChunkName: "component---src-pages-nl-verzending-js" */),
  "component---src-pages-nl-winkelwagen-js": () => import("./../../../src/pages/nl/winkelwagen.js" /* webpackChunkName: "component---src-pages-nl-winkelwagen-js" */),
  "component---src-pages-our-story-js": () => import("./../../../src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-returns-js": () => import("./../../../src/pages/returns.js" /* webpackChunkName: "component---src-pages-returns-js" */),
  "component---src-pages-shipping-js": () => import("./../../../src/pages/shipping.js" /* webpackChunkName: "component---src-pages-shipping-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-templates-product-page-index-js": () => import("./../../../src/templates/ProductPage/index.js" /* webpackChunkName: "component---src-templates-product-page-index-js" */)
}

